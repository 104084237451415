import { Controller } from "stimulus";
import ClipboardJS from "clipboard"
import tippy from "tippy.js";

export default class extends Controller {
  test(event) {
    event.preventDefault();
    let id = event.target.dataset.id;
    document.getElementById('server_test_icon').classList = 'fa fa-spinner fa-spin';
    let data = {}

    if (document.getElementById('server-form')) {
      data = {
        server: {
          name: document.getElementById('server_name').value,
          host_name: document.getElementById('server_host_name').value,
          users: document.getElementById('server_users').value,
          port: document.getElementById('server_port').value,
          private_key: document.getElementById('server_private_key').value,
        }
      }
    }

    Rails.ajax({
      url: `/servers/test?id=${id}`,
      type: 'PATCH',
      data: JSON.stringify(data),
      success: () => {
        document.getElementById('server_test_icon').classList = 'fa fa-check';
        document.getElementById('server_test_message').textContent = '';
      },
      error: (response) => {
        document.getElementById('server_test_icon').classList = 'fa fa-times';
        document.getElementById('server_test_message').textContent = response.error;
      }
    });
  }
}
